const getOptional = (key) => {
  return process.env[key];
};

export const AppConfigs = {
  awsAppSyncApikey: getOptional('REACT_APP_AWS_APPSYNC_APIKEY') || '',
  awsAppSyncGraphql: getOptional('REACT_APP_AWS_APPSYNC_GRAPHQL') || '',
  stage: getOptional('REACT_APP_STAGE') || 'dev',
  webUrl: getOptional('REACT_APP_WEB_URL') || 'https://weconnect.sg',
  apiUrl: getOptional('REACT_APP_API_URL') || 'https://api.dev.weconnect.sg',
  apiV2Url: getOptional('REACT_APP_API_V2_URL') || '',
};
