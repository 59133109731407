import './index.style.less';
import UserRole from './UserRole';

const UserEditRole = () => {
  return (
    <div className='user-profile-container'>
      <UserRole />
    </div>
  );
};

export default UserEditRole;
