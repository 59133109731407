import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';

const GroupPage = React.lazy(() => import('./GroupPage'));
const GroupEditPage = React.lazy(() => import('./UserEdit'));
const GroupStatisticPage = React.lazy(() => import('./GroupStatisticPage'));
const GroupDetailPage = React.lazy(() => import('./GroupDetailPage'));

export const notificationPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.community,
    path: '/group/statistic',
    element: <GroupStatisticPage />,
  },
  {
    permittedRole: RoutePermittedRole.community,
    path: '/group/:id/edit',
    element: <GroupEditPage />,
  },
  {
    permittedRole: RoutePermittedRole.community,
    path: '/group/:id',
    element: <GroupDetailPage />,
  },
  {
    permittedRole: RoutePermittedRole.community,
    path: '/group',
    element: <GroupPage />,
  },
];
