import React from 'react';
import PropTypes from 'prop-types';
import ErrorIcon from './ErrorIcon';
import './index.style.less';
import {Button, Space} from 'antd';

class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    console.log('error: ', error);

    /**
     * Reload to try to download chunks again
     */
    // window.location.reload();

    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log('errorInfo: ', errorInfo);
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='error-boundary'>
          <ErrorIcon />
          <div style={{fontSize: 30, marginTop: 4}}></div>
          <div style={{fontSize: 18, textAlign: 'center'}}></div>
          <div style={{fontSize: 18, textAlign: 'center'}}></div>
          <Space>
            <Button
              type='primary'
              onClick={() => {
                window.location.reload();
              }}>
              Reload
            </Button>
            <Button
              onClick={() => {
                window.location.href = '/';
              }}>
              Home Page
            </Button>
          </Space>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

AppErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppErrorBoundary;
