import {Dropdown, Layout, Menu} from 'antd';
import {FiMoreVertical} from 'react-icons/fi';
import AppLogo from '../components/AppLogo';
import UserInfo from '../components/UserInfo';
import './index.style.less';

const AppHeader = () => {
  const {Header} = Layout;

  const menuMobile = <Menu></Menu>;

  return (
    <Header className='app-userMiniHeader'>
      <AppLogo />

      <div className='userMiniHeader-search' />
      <div className='app-userMiniHeader-sectionDesktop'>
        {/* <AppNotifications /> */}
      </div>
      <UserInfo />
      <div className='app-userMiniHeader-section-mobile'>
        <Dropdown overlay={menuMobile} trigger={['click']}>
          <a className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
            <FiMoreVertical />
          </a>
        </Dropdown>
      </div>
    </Header>
  );
};

export default AppHeader;

AppHeader.propTypes = {};
