import {UploadOutlined} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {AppRowContainer} from '../../../../@crema';
import jwtAxios from '../../../../@crema/services/auth/jwt-auth/jwt-api';
import {useAuthMethod, useAuthUser} from '../../../../@crema/utility/AuthHooks';
import './index.style.less';

export function sendFile(presignedUrl, file) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.timeout = 60000;

    xhr.onreadystatechange = function (e) {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          let urlSplit = presignedUrl.split('?');
          resolve(urlSplit[0]);
        } else {
          reject(e);
        }
      }
    };
    xhr.ontimeout = function (e) {
      reject(e);
    };
    xhr.open('PUT', presignedUrl);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
  });
}

const PersonalInfo = () => {
  const {user} = useAuthUser();
  const {getUserProfile} = useAuthMethod();

  const [userImage, setUserImage] = useState(
    user?.profile?.avatar || '/assets/images/placeholder.jpg',
  );

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: async (acceptedFiles) => {
      setUserImage(URL.createObjectURL(acceptedFiles[0]));
      const {data} = await jwtAxios.get('/file/prepare-upload', {
        params: {
          contentType: acceptedFiles[0].type,
          fileName: acceptedFiles[0].name,
          type: 'avatar',
        },
      });

      const url = await sendFile(data.url, acceptedFiles[0]);
      setUserImage(url);
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState({});

  const getContent = () => {
    jwtAxios
      .get('content')
      .then((res) => {
        setContent(res.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getContent();
  }, []);

  const onFinish = async (values) => {
    const request = {
      ...user.meetingPreference,
      ...user.profile,
      ...values,
      avatar: userImage,
      dob: values.dob.format('YYYY-MM-DD'),
      profileExpertises: values.profileExpertises.map((expertise) => ({
        profileId: user.profileId,
        expertiseId: expertise,
      })),
      profileLearnings: values.profileLearnings.map((x) => ({
        profileId: user.profileId,
        learningId: x,
      })),
    };
    setIsLoading(true);
    await jwtAxios
      .put('me', request)
      .then(() => {
        getUserProfile();
        setIsLoading(false);
        message.success('Profile updated successfully.');
      })
      .catch(({response}) => {
        message.error(response?.data?.message || 'Something went wrong.');
        setIsLoading(false);
      });
  };

  return (
    <Form
      onFinish={onFinish}
      layout='vertical'
      initialValues={{
        ...user.profile,
        userImage: user.profile?.avatar
          ? user.profile?.avatar
          : '/assets/images/placeholder.jpg',
        dob: moment(user.profile?.dob),
        tags: [],
        email: user.email,
        profileExpertises: user.profile.profileExpertises?.map(
          (x) => x.expertiseId,
        ),
        profileLearnings: user.profile.profileLearnings?.map(
          (x) => x.learningId,
        ),
      }}>
      <h3 className='user-profile-form-title title-primary'>MY PROFILE</h3>
      <p className='user-profile-form-description'>
        Just a few more information for us to know you better
      </p>
      <Row gutter={32} style={{paddingBottom: 80}}>
        <Col md={6} xs={24}>
          <Form.Item className='info-upload'>
            <Avatar className='info-upload-avatar' size={236} src={userImage} />
            <div className='info-upload-content'>
              <div className='info-upload-btn-group'>
                <div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  <label htmlFor='icon-button-file'>
                    <Button danger icon={<UploadOutlined />}>
                      UPLOAD PHOTO
                    </Button>
                  </label>
                </div>
              </div>
            </div>
          </Form.Item>
        </Col>
        <Col md={18}>
          <AppRowContainer gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                name='firstName'
                label={'First Name'}
                requiredMark={'optional'}
                rules={[
                  {required: true, message: 'Please input your First Name!'},
                ]}>
                <Input placeholder='First Name' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name='lastName'
                label={'Surname'}
                requiredMark={'optional'}
                rules={[
                  {required: true, message: 'Please input your Surname!'},
                ]}>
                <Input placeholder='Surname' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name='dob'
                label={'Date of Birth'}
                requiredMark={'optional'}
                rules={[
                  {required: true, message: 'Please input your Date of Birth!'},
                ]}>
                <DatePicker
                  placeholder='Date of Birth'
                  style={{width: '100%'}}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name='gender'
                label={'Gender'}
                requiredMark={'optional'}
                rules={[
                  {required: true, message: 'Please input your Gender!'},
                ]}>
                <Select placeholder='Gender'>
                  <Select.Option value='Male'>Male</Select.Option>
                  <Select.Option value='Female'>Female</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name='email'
                label={'Email'}
                requiredMark={'optional'}
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                ]}>
                <Input type='text' disabled placeholder='Email' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label={'Level'}>
                <Input
                  type='text'
                  disabled
                  value={`${user.accountType} ${
                    user.profile?.level >= 0
                      ? `Level ${user.profile.level}`
                      : ''
                  }`}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}></Col>
            <Col xs={24} md={24}>
              <Form.Item name='personalStatement' label={'Personal Statement'}>
                <Input type='text' placeholder='Enter Personal Statement' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name='intro' label={'Introduction'}>
                <Input.TextArea
                  type='text'
                  rows={4}
                  placeholder='Enter Introduction'
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name='mentorshipExpectation'
                label={'What do you hope to get out of this mentorship?'}>
                <Input.TextArea
                  type='text'
                  rows={4}
                  placeholder='Enter Expectation'
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name='religionId' label={'Religion'}>
                <Select placeholder='Select'>
                  {content.religions?.map((x) => {
                    return (
                      <Select.Option key={x.id} value={x.id}>
                        {x.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name='profileLearnings'
                label={'What are you keen to learn?'}>
                <Select
                  placeholder='Select'
                  showSearch
                  allowClear
                  optionFilterProp='name'
                  mode='multiple'
                  filterOption={(input, option) => {
                    return option.name
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}>
                  {_.orderBy(content.learnings, (x) => x.name)?.map((x) => {
                    return (
                      <Select.Option key={x.id} value={x.id} name={x.name}>
                        {x.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name='webLink' label={'Web Link'}>
                <Input type='text' placeholder='https://' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}></Col>
            <Col xs={24} md={12}>
              <Form.Item label={'Areas of Expertise'} name='profileExpertises'>
                <Select
                  placeholder='Select'
                  mode='multiple'
                  showSearch
                  allowClear
                  optionFilterProp='name'
                  filterOption={(input, option) => {
                    return option.name
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}>
                  {_.sortBy(content.expertise, ['categoryName', 'name'])?.map(
                    (x) => {
                      return (
                        <Select.Option key={x.id} value={x.id} name={x.name}>
                          {x.name}
                        </Select.Option>
                      );
                    },
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name='industryId' label={'Industry'}>
                <Select placeholder='Select'>
                  {content.industries?.map((industry) => {
                    return (
                      <Select.Option key={industry.id} value={industry.id}>
                        {industry.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </AppRowContainer>
        </Col>
      </Row>
      <Space className='pb-5' size={100} />
      <Col xs={24} md={24} className='user-profile-footer'>
        <div className='container user-profile-footer_content'>
          <div />
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Save
          </Button>
        </div>
      </Col>
    </Form>
  );
};

export default PersonalInfo;
