import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';

const DashboardPage = React.lazy(() => import('./DashboardPage'));

export const reportPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.report,
    path: '/report',
    element: <DashboardPage />,
  },
];
