import {Button, Card, Col, Row, Space, Spin} from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import _ from 'lodash';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {getDataApi} from '../../../@crema/utility/APIHooks';
import {getShortDateTimeDisplay} from '../../../@crema/utility/Utils';
import './index.style.less';

const RoleDetailPage = () => {
  const [loading, setLoading] = useState(false);
  const {id} = useParams();
  const [role, setRole] = useState({});
  const navigate = useNavigate();

  const getGift = async () => {
    setLoading(true);
    const data = await getDataApi(`/admin/roles/${id}`, {});
    setLoading(false);
    if (data) {
      setRole(data);
    }
  };

  useEffect(() => {
    getGift();
  }, []);

  return (
    <Spin spinning={loading}>
      <div className='d-space-between'>
        <Button type='link' className='pl-0 mb-16' onClick={() => navigate(-1)}>
          <span className='we-icon icon-ic_arrow-left mr-16'></span>
          <span className='pl-16'>BACK</span>
        </Button>
        <Space>
          <Button
            type='primary'
            onClick={() => navigate(`/role/${role?.id}/edit`)}>
            Edit
          </Button>
        </Space>
      </div>
      <Row gutter={8} className='info-avatar'>
        <Col md={24} xs={24}>
          <Card className='card__info'>
            <Title level={4}>{role.name}</Title>
            <Row gutter={16}>
              <Col md={8} xs={24}>
                <Title level={5}>Description</Title>
                <Text>{role?.description}</Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Created At</Title>
                <Text>{getShortDateTimeDisplay(role.createdAt)}</Text>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={8} xs={24}>
                <Title level={5}>Permission</Title>
                <Text>
                  {_.uniqBy(
                    role.rolePermissions
                      ?.map((x) => x.permission.permissionInfo?.description)
                      ?.filter((x) => x),
                    (y) => y,
                  )?.map((x) => (
                    <Text key={x}>
                      {x}
                      <br />
                    </Text>
                  ))}
                </Text>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

export default RoleDetailPage;
