import React from 'react';

const LandingPage = React.lazy(() => import('./LandingPage'));

export const homePagesConfigs = [
  {
    path: '/home',
    element: <LandingPage />,
  },
];
