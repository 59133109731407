import './index.style.less';
import PersonalInfo from './PersonalInfo';

const UserEdit = () => {
  return (
    <div className='user-profile-container'>
      <PersonalInfo />
    </div>
  );
};

export default UserEdit;
