import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';
import UserEdit from './UserEdit';
import UserEditRole from './UserEdit/UserEditRole';
import ConnectionsPage from './UserPage/ConnectionsPage';
import ScheduleDetailPage from './UserPage/ScheduleDetailPage';
import SchedulesPage from './UserPage/SchedulesPage';
import UserDetail from './UserPage/UserDetail';
import UserPage from './UserPage';

const TempUsers = React.lazy(() => import('./UserPage/TempUsers'));

export const userPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/new-user',
    element: <TempUsers />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/user',
    element: <UserPage />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/user/:id/role',
    element: <UserEditRole />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/user/:id/edit',
    element: <UserEdit />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/user/create',
    element: <UserEdit />,
  },
  {
    permittedRole: RoutePermittedRole.mentorship,
    path: '/user/:id/connections',
    element: <ConnectionsPage />,
  },
  {
    permittedRole: RoutePermittedRole.session,
    path: '/schedules',
    element: <SchedulesPage />,
  },
  {
    permittedRole: RoutePermittedRole.session,
    path: '/schedules/:id',
    element: <ScheduleDetailPage />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/user/:id',
    element: <UserDetail />,
  },
];
