import {DownloadOutlined} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {getDataApi, postDataApi} from '../../../@crema/utility/APIHooks';
import {getDateDisplay3} from '../../../@crema/utility/Utils';

const {Search} = Input;
const {RangePicker} = DatePicker;

const UsersPage = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(1);
  const [query, setQuery] = useState({
    pagination: {
      pageSize: 10,
      current: 0,
    },
  });
  const [keyword, setKeyword] = useState('');
  const [level, setLevel] = useState(undefined);
  const [isActive, setIsActive] = useState(undefined);
  const [lastActiveRange, setLastActiveRange] = useState([]);

  const onLastActiveRangeChange = (dates, dateStrings) => {
    if (dates) {
      setLastActiveRange([
        moment(dateStrings[0]).toISOString(),
        moment(dateStrings[1]).endOf('days').toISOString(),
      ]);
    } else {
      console.log('Clear');
      setLastActiveRange([]);
    }
  };

  const getUsers = async (options) => {
    setLoading(true);
    let levelQuery = level;
    let isMentor = undefined;

    if (level === -2) {
      isMentor = true;
      levelQuery = undefined;
    }
    const res = await getDataApi(`/admin/users`, {
      skip: (query.pagination?.current - 1) * query.pagination?.pageSize || 0,
      take: query.pagination?.pageSize || 10,
      search: keyword,
      level: levelQuery,
      isMentor,
      isActive,
      lastActiveFrom: lastActiveRange[0],
      lastActiveTo: lastActiveRange[1],
      ...options,
    });

    setUsers(res.data);
    setTotal(res.total);
    setLoading(false);
  };

  const onExportUsers = async () => {
    setLoading(true);
    let levelQuery = level;
    let isMentor = undefined;

    if (level === -2) {
      isMentor = true;
      levelQuery = undefined;
    }

    await postDataApi(
      `/users/export`,
      {},
      {
        config: {
          responseType: 'blob',
          params: {
            lastActiveFrom: lastActiveRange[0],
            lastActiveTo: lastActiveRange[1],
            isMentor,
            level: levelQuery,
          },
        },
        fetchSuccess: (data) => {
          const blob = new Blob([data], {type: 'text/csv'});
          const a = document.createElement('a');
          a.download = `users_${moment().format('MMDDYYYYHHmm')}.csv`;
          a.href = window.URL.createObjectURL(blob);
          const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
          });
          a.dispatchEvent(clickEvt);
          a.remove();
        },
      },
    );

    setLoading(false);
  };

  useEffect(() => {
    if (query.pagination?.current) {
      getUsers();
    }
  }, [
    level,
    isActive,
    lastActiveRange,
    query.pagination?.current,
    query.pagination?.pageSize,
  ]);

  const onSearch = (value) => {
    setKeyword(value);
    getUsers({search: value});
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      sorter: false,
    },
    {
      title: 'Surname',
      dataIndex: 'lastName',
      sorter: false,
    },
    {
      title: 'Level',
      dataIndex: 'level',
      sorter: false,
      render: (_r, {level}) => {
        let color = level === -1 ? 'blue' : level === 0 ? 'orange' : 'red';

        const levelName = level === -1 ? 'Mentee' : `Mentor ${level}`;

        return (
          <Tag color={color} key={level}>
            {levelName}
          </Tag>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: false,
    },
    {
      title: 'Date of Birth',
      dataIndex: 'dob',
      sorter: false,
      render: (_r, {dob}) => {
        return getDateDisplay3(dob);
      },
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      sorter: false,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      sorter: false,
    },
    {
      title: 'Last time login',
      dataIndex: 'lastActive',
      sorter: false,
      render: (_r, {lastActive}) => {
        return lastActive ? getDateDisplay3(lastActive) : '';
      },
    },
    {
      title: 'Account Status',
      dataIndex: 'isActive',
      sorter: false,
      render: (_r, {isActive}) => {
        return isActive ? 'Active' : 'Inactive';
      },
    },
    {
      title: '',
      dataIndex: '',
      sorter: false,
      render: (r) => {
        return (
          <Space>
            <Button
              type='primary'
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/user/${r.id}/edit`);
              }}>
              Edit
            </Button>
            <Button
              type='ghost'
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/user/${r.id}/connections`);
              }}>
              Connection
            </Button>
            <Button
              type='ghost'
              danger
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/user/${r.id}/role`);
              }}>
              Role
            </Button>
          </Space>
        );
      },
    },
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const onChange = (pagination, filters, sorter, extra) => {
    setQuery({
      pagination,
      filters,
      sorter,
      extra,
    });
    setSearchParams({page: pagination.current});
  };

  useEffect(() => {
    const params = Object.fromEntries(searchParams.entries());
    setQuery({
      ...query,
      pagination: {
        ...query.pagination,
        current: parseInt(params.page || 1),
      },
    });
  }, [searchParams]);

  const navigate = useNavigate();

  return (
    <Row>
      <Title level={1} strong>
        User Management
      </Title>
      <Col xs={24}>
        <Space>
          <Search
            placeholder='Search by name or email'
            onSearch={onSearch}
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
            style={{width: 400}}
          />
          <Select
            style={{width: 220}}
            value={level}
            placeholder='All Level'
            allowClear
            onChange={(e) => setLevel(e)}>
            <Select.Option value={-1}>Mentee</Select.Option>
            <Select.Option value={0}>Mentor Level 0</Select.Option>
            <Select.Option value={1}>Mentor Level 1</Select.Option>
            <Select.Option value={2}>Mentor Level 2</Select.Option>
            <Select.Option value={-2}>All Mentors</Select.Option>
            <Select.Option value={undefined}>All</Select.Option>
          </Select>
          <RangePicker
            ranges={{
              'Last week': [
                moment().startOf('weeks').add(-7, 'days'),
                moment().endOf('weeks').add(-7, 'days'),
              ],
              'Last 3 months': [
                moment().add(-3, 'months').startOf('months'),
                moment().add(-3, 'months').endOf('months'),
              ],
              'Last 6 months': [
                moment().add(-6, 'months').startOf('months'),
                moment().add(-6, 'months').endOf('months'),
              ],
              'Last 12 months': [
                moment().add(-12, 'months').startOf('months'),
                moment().add(-12, 'months').endOf('months'),
              ],
              'This year(Jan - Today)': [moment().startOf('years'), moment()],
            }}
            onChange={onLastActiveRangeChange}
          />
          <Select
            style={{width: 220}}
            value={isActive}
            placeholder='Account Status'
            allowClear
            onChange={(e) => setIsActive(e)}>
            <Select.Option value={true}>Active</Select.Option>
            <Select.Option value={false}>Inactive</Select.Option>
          </Select>
          <Button size='large' type='outline' onClick={onExportUsers}>
            <DownloadOutlined />
            Export
          </Button>
          <Button
            size='large'
            type='primary'
            onClick={() => navigate('/user/create')}>
            Create
          </Button>
        </Space>
      </Col>
      <Col xs={24}>
        <Table
          bordered
          columns={columns}
          dataSource={users}
          loading={loading}
          onChange={onChange}
          pagination={{
            total: total,
            current: query.pagination.current || 1,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} users of ${total}`,
          }}
          onRow={(record) => {
            return {
              onClick: (e) => {
                e.preventDefault();
                navigate(`/user/${record.id}`);
              },
            };
          }}
        />
      </Col>
    </Row>
  );
};

export default UsersPage;
