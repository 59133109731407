import {UserOutlined} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Col,
  message,
  Modal,
  Radio,
  Row,
  Space,
  Spin,
  Tag,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  getDataApi,
  patchDataApi,
  putDataApi,
} from '../../../@crema/utility/APIHooks';
import {getDateDisplay} from '../../../@crema/utility/Utils';
import './index.style.less';

const UserDetail = () => {
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const {id} = useParams();
  const [user, setUser] = useState({});
  const [visible, setVisible] = useState(false);
  const [level, setLevel] = useState(-1);
  const navigate = useNavigate();

  const getUser = async () => {
    setLoading(true);
    const data = await getDataApi(`users/all/${id}`, {});
    setLoading(false);
    if (data) {
      setUser(data);
      setLevel(data?.profile?.level);
    }
  };

  const getClassNameAccType = (accType) => {
    if (accType === 'Member') return 'member';
    if (accType === 'Mentee') return 'mentee';
    if (accType === 'Mentor') return 'mentor';
    return 'mentee';
  };

  const onChangeLevel = (v) => {
    setLevel(v.target.value);
  };

  const updateLevel = async () => {
    setUpdating(true);
    const res = await putDataApi(`/admin/users/${id}/level`, {level});
    setUpdating(false);
    if (res) {
      message.success("User's level is updated.");
    }
    getUser();
    setVisible(false);
  };

  const activateUser = async () => {
    setUpdating(true);
    const res = await patchDataApi(`/admin/users/${id}/active`, {
      isActive: !user.isActive,
    });
    setUpdating(false);

    if (res) {
      message.success('Updated successfully.');
    }
    getUser();
    setVisible(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  const getLevelName = (level) => {
    return level === -1 ? 'Mentee' : `Mentor ${level}`;
  };

  return (
    <Spin spinning={loading}>
      <Button type='link' className='pl-0 mb-16' onClick={() => navigate(-1)}>
        <span className='we-icon icon-ic_arrow-left mr-16'></span>
        <span className='pl-16'>BACK</span>
      </Button>
      <Row gutter={8}>
        <Col md={18} xs={24} className='detail__header'>
          <div className='detail__header--name-type'>
            <Title level={3}>{user?.name}</Title>
            <Tag className={getClassNameAccType(user?.accountType)}>
              {getLevelName(user?.profile?.level)}
            </Tag>
          </div>
          <Space>
            <Button onClick={() => setVisible(true)}>UPDATE LEVEL</Button>
            <Button type='primary' danger onClick={() => activateUser()}>
              {user.isActive ? 'Deactivate' : 'Activate'}
            </Button>
            <Button
              type='primary'
              onClick={() => navigate(`/user/${user?.id}/edit`)}>
              Edit
            </Button>
          </Space>
        </Col>
        <Col md={6} xs={24}></Col>
      </Row>
      <Row gutter={8} className='info-avatar'>
        <Col md={18} xs={24}>
          <Card className='card__info'>
            <Title level={4}>General Information</Title>
            <Row gutter={16}>
              <Col md={8} xs={24}>
                <Title level={5}>Email</Title>
                <Text>{user?.email}</Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Day of Birth</Title>
                <Text>{getDateDisplay(user?.profile?.dob)}</Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Gender</Title>
                <Text>{user?.profile?.gender}</Text>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={8} xs={24}>
                <Title level={5}>Status</Title>
                <Text>{user?.isActive ? 'Active' : 'Inactive'}</Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Role</Title>
                <Text>
                  {user?.role?.name} ({user?.role?.description})
                </Text>
              </Col>
            </Row>
          </Card>

          <Card className='card__info'>
            <Title level={4}>Personal Information</Title>
            <Row gutter={16}>
              <Col md={8} xs={24}>
                <Title level={5}>Personal Statement</Title>
                <Text>{user?.profile?.personalStatement || '-'}</Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Introduction</Title>
                <Text>{user?.profile?.intro}</Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>
                  What do you hope to get out of this mentorship?
                </Title>
                <Text>{user?.profile?.mentorshipExpectation || '-'}</Text>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={8} xs={24}>
                <Title level={5}>Religion</Title>
                <Text>{user?.profile?.religion?.name || '-'}</Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>What are you keen to learn?</Title>
                <Text>
                  <ul>
                    {user?.profile?.profileLearnings?.map((e) => (
                      <li key={e.learningId}>{e.learning.name}</li>
                    ))}
                  </ul>
                </Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Web Link</Title>
                {user?.profile?.webLink ? (
                  <a href={user?.profile?.webLink}>
                    {user?.profile?.webLink || '-'}
                  </a>
                ) : (
                  <Text>-</Text>
                )}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={8} xs={24}>
                <Title level={5}>Area of Expertise</Title>
                <Text>-</Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Industry</Title>
                <Text>{user?.profile?.industry?.name || '-'}</Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Is Feature?</Title>
                <Text>{user?.profile?.isFeature ? 'Yes' : 'No'}</Text>
              </Col>
            </Row>
          </Card>
          <Card className='card__info'>
            <Title level={4}>Contact Information</Title>
            <Row gutter={16}>
              <Col md={8} xs={24}>
                <Title level={5}>Country of Residences</Title>
                <Text>{user?.profile?.contactInfo?.country?.name || '-'}</Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Postal Code</Title>
                <Text>{user?.profile?.contactInfo?.postalCode}</Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Contact Number</Title>
                <Text>{user?.profile?.contactInfo?.phone}</Text>
              </Col>
            </Row>
          </Card>
          <Card className='card__info'>
            <Title level={4}>Experience</Title>
            <Row gutter={16}>
              <Col md={8} xs={24}>
                <Title level={5}>Current Designation</Title>
                <Text>
                  {user?.profile?.contactInfo?.currentDesignation || ''}
                </Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Mentoring Organisation</Title>
                <Text>{user?.profile?.contactInfo?.mentoringOrganisation}</Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Job Role</Title>
                <Text>{user?.profile?.jobRole?.name}</Text>
              </Col>
              <Col md={8} xs={24} className='mt-24'>
                <Title level={5}>Job Function</Title>
                <Text>{user?.profile?.jobFunction?.name}</Text>
              </Col>
            </Row>
          </Card>
          <Card className='card__info'>
            <Title level={4}>Social Cause</Title>
            <Row gutter={16}>
              {user?.profile?.profileSocialCauses?.map((e) => (
                <Tag key={e.socialCauseId}>{e.socialCause.name}</Tag>
              ))}
            </Row>
          </Card>
          <Card className='card__info'>
            <Title level={4}>Mentor Reference</Title>
            <Row gutter={16}>
              <Col md={8} xs={24}>
                <Title level={5}>Mentor Role</Title>
                <Text>
                  {user?.profile?.profileMentorRoles
                    ?.map((x) => x.mentorRole?.name)
                    ?.join(', ') || '-'}
                </Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Mentor Reference</Title>
                <Text>{user?.meetingPreference?.mentorGender?.join(', ')}</Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Preferred Meeting Day</Title>
                <Text>{user?.meetingPreference?.meetingDay?.join(', ')}</Text>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={8} xs={24}>
                <Title level={5}>Preferred Meeting Time</Title>
                <Text>{user?.meetingPreference?.meetingTime?.join(', ')}</Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Preferred Meeting Format</Title>
                <Text>
                  {user?.meetingPreference?.meetingFormat?.join(', ')}
                </Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Preferred Meeting Frequency</Title>
                <Text>
                  {user?.meetingPreference?.meetingFrequency?.join(', ')}
                </Text>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={8} xs={24}>
                <Title level={5}>Life Stage - Career</Title>
                <Text>
                  <ul>
                    {user?.profile?.profileLifeStages
                      ?.filter((e) => e.lifeStage.group === 'Career')
                      .map((e) => (
                        <li key={e.lifeStage.id}>{e.lifeStage.name}</li>
                      ))}
                  </ul>
                </Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Life Stage - Family</Title>
                <Text>
                  <ul>
                    {user?.profile?.profileLifeStages
                      ?.filter((e) => e.lifeStage.group === 'Family')
                      .map((e) => (
                        <li key={e.lifeStage.id}>{e.lifeStage.name}</li>
                      ))}
                  </ul>
                </Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Life Stage - Self</Title>
                <Text>
                  <ul>
                    {user?.profile?.profileLifeStages
                      ?.filter((e) => e.lifeStage.group === 'Self')
                      .map((e) => (
                        <li key={e.lifeStage.id}>{e.lifeStage.name}</li>
                      ))}
                  </ul>
                </Text>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={6} xs={24} style={{textAlign: 'center'}}>
          <Avatar size={280} icon={<UserOutlined />} src={user?.avatar} />
        </Col>
        <Modal
          visible={visible}
          onCancel={() => setVisible(false)}
          onOk={updateLevel}
          destroyOnClose
          okText='UPDATE'
          cancelText='DISCARD'
          title="UPDATE USER'S LEVEL">
          <Spin spinning={updating}>
            <Radio.Group onChange={onChangeLevel} value={level}>
              <Space direction='vertical'>
                <Radio value={-1}>Member</Radio>
                <Radio value={0}>Mentor Level 0</Radio>
                <Radio value={1}>Mentor Level 1</Radio>
                <Radio value={2}>Mentor Level 2</Radio>
              </Space>
            </Radio.Group>
          </Spin>
        </Modal>
      </Row>
    </Spin>
  );
};

export default UserDetail;
