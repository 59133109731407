import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Spin,
} from 'antd';
import _ from 'lodash';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {AppRowContainer} from '../../../../@crema';
import {getDataApi, putDataApi} from '../../../../@crema/utility/APIHooks';
import './index.style.less';

const RoleEditPage = () => {
  const [role, setRole] = useState({});
  const [permissions, setPermissions] = useState([]);
  const navigate = useNavigate();
  const {id} = useParams();
  const isCreate = !id;

  const [isLoading, setIsLoading] = useState(false);

  const getRole = async () => {
    setIsLoading(true);
    const data = await getDataApi(`admin/roles/${id}`, {});
    setIsLoading(false);
    if (data) {
      setRole(data);
    }
  };

  const getPermissions = async () => {
    setIsLoading(true);
    const data = await getDataApi(`admin/roles/permissions`, {});
    setIsLoading(false);
    if (data) {
      setPermissions(data);
    }
  };

  useEffect(() => {
    if (!isCreate) {
      getRole();
    }
    getPermissions();
  }, []);

  const onFinish = async (values) => {
    const request = {
      ...values,
      id: role?.id,
    };

    setIsLoading(true);

    await putDataApi('/admin/roles', request, {
      fetchSuccess: (res) => {
        setIsLoading(false);
        message.success('Success.');
        navigate(`/role/${res.id}`);
      },
      fetchError: () => {
        setIsLoading(false);
      },
    });
  };

  if (!role?.id && !isCreate) {
    return (
      <>
        <Spin spinning />
      </>
    );
  }

  return (
    <Form
      onFinish={onFinish}
      layout='vertical'
      initialValues={{
        ...role,
        permissionResources: _.uniqBy(
          role.rolePermissions
            ?.map((x) => x.permission.resourceName)
            ?.filter((x) => x),
          (y) => y,
        ),
      }}>
      <Button type='link' className='pl-0' onClick={() => navigate(-1)}>
        <span className='we-icon icon-ic_arrow-left mr-16'></span>
        <span className='pl-16'>BACK</span>
      </Button>
      <h3 className='user-profile-form-title title-primary'>
        {isCreate ? 'CREATE ROLE' : 'EDIT ROLE'}
      </h3>
      <Row gutter={32} style={{paddingBottom: 80}}>
        <Col md={24}>
          <AppRowContainer gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                name='name'
                label={'Name'}
                requiredMark={'optional'}
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input placeholder='Name' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name='description'
                label={'Description'}
                requiredMark={'optional'}
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input placeholder='Description' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name='permissionResources' label={'Permission'}>
                <Checkbox.Group placeholder='Select' disabled={role?.id <= 2}>
                  {_.orderBy(
                    _.uniqBy(permissions, (x) => x.resourceName)
                      .filter((x) => x.resourceName)
                      .map((p) => ({
                        value: p.resourceName,
                        label: p.permissionInfo?.description,
                      })),
                    (x) => x.label,
                  ).map((x) => (
                    <>
                      <Checkbox key={x.value} value={x.value}>
                        {x.label}
                      </Checkbox>
                      <br />
                    </>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </AppRowContainer>
        </Col>
      </Row>
      <Space className='pb-5' size={100} />
      <Col xs={24} md={24} className='user-profile-footer'>
        <div className='container user-profile-footer_content'>
          <div />
          <Button type='primary' htmlType='submit' loading={isLoading}>
            {isCreate ? 'Create' : 'Save'}
          </Button>
        </div>
      </Col>
    </Form>
  );
};

export default RoleEditPage;
