/* eslint-disable react/prop-types */
import {Col, Row} from 'antd';
import './index.style.less';

const Footer = ({fixed}) => {
  const year = new Date().getFullYear();
  return (
    <Row className={`footer ${fixed && 'footer-fixed'}`}>
      <Col md={20} xs={24} offset={2} className='mb-0 text-center'>
        <span>© Copyright {year} MentoringSG. All rights reserved.</span>
      </Col>
    </Row>
  );
};

export default Footer;
