import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';

const MentorRequestPage = React.lazy(() => import('./MentorRequestPage'));
const MentorRequestDetailPage = React.lazy(() =>
  import('./MentorRequestDetailPage'),
);

export const mentorRequestPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.mentorRequest,
    path: '/mentor-request/:id',
    element: <MentorRequestDetailPage />,
  },
  {
    permittedRole: RoutePermittedRole.mentorRequest,
    path: '//mentor-request',
    element: <MentorRequestPage />,
  },
];
