/* eslint-disable react/prop-types */
import {Col, Row} from 'antd';
import Title from 'antd/lib/typography/Title';
import {useEffect, useState} from 'react';
import {getDataApi} from '../../../@crema/utility/APIHooks';
import {getDateDisplay} from '../../../@crema/utility/Utils';
import Footer from '../../../components/Footer';
import SlateEditor from '../slates/SlateEditor/Editor';
import './index.style.less';

const TermAndCondition = () => {
  const [article, setArticle] = useState({});

  useEffect(async () => {
    const res = await getDataApi(
      `/content/articles/category/TermsAndConditions`,
    );
    setArticle(res?.data || {});
  }, []);

  return (
    <>
      <div className='article-detail terms-and-conditions'>
        <div className='container article-detail__content'>
          <Row className='row d-space-between'>
            <Col xs={18} md={22}>
              <Title level={2}>{article.title}</Title>
              <p>Updated on {getDateDisplay(article.updatedAt)} </p>
            </Col>
            <Col xs={6} md={1}></Col>
          </Row>
          {!!article.data && (
            <SlateEditor isView={true} initialValue={article.data} />
          )}
        </div>
      </div>
      <Footer fixed />
    </>
  );
};

export default TermAndCondition;
